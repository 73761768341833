.loginPage {
    background: url('../public/Images/employee\ ms.jpeg');
    background: linear-gradient(rgba(11, 11, 11, 0.5), rgba(10, 10, 10, 0.5)), url("../public/Images/employee\ ms.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 120%;
}

.loginForm {
    background-color: rgba(1, 32, 93, 0.6);
    color: white;
}

.employee_image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.empImg {
    width: 400px;
    height: 400px;
    border-radius: 50%;
}

.sideBarHover {
    width: 110%;
}

.sideBarHover:hover {
    background-color: gray;
}

.payTable {
    width: 100%;
    margin-left: 3%;
}

.payrollDiv {
    width: 40%;
    margin: 0 auto;
    border: black 1px solid;
    border-radius: 20px;
}

/* .leavePage { */
    /* text-align: center; */
    /* display: 'flex',
    justifyContent: 'center' */
/* } */

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

.popupContainer {
    position: fixed;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.popupMessage {
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.popupAppraisalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

.popupAppraisalContainer {
    position: fixed;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.popupAppraisalMessage {
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    height: 500px;
    overflow-y: auto;
}